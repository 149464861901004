import type { LegalFooterConfig } from '@xing-com/platform-layout-footer';

import type { ConfigurationItem, Language, Translate } from '../types';

export const legalFooter: ConfigurationItem = (
  t: Translate,
  lang: Language
): LegalFooterConfig => [
  [
    'FooterBox',
    {
      children: {
        $meta: [
          'FooterBranding',
          [
            'FooterNav',
            {
              'data-qa': 'frame-footer-legal-nav',
              children: {
                $meta: [
                  [
                    'FooterNavLinks',
                    {
                      children: {
                        $meta: [
                          [
                            'FooterNavItem',
                            {
                              key: 'imprintFooter',
                              title: t('VERTICAL_NAVIGATION_FOOTER_IMPRINT'),
                              href: 'https://www.xing.com/legalnotice',
                              tracking: {
                                sc_o: 'navigation_footer',
                              },
                            },
                          ],
                          [
                            'FooterNavItem',
                            {
                              key: 'tAndCFooter',
                              title: t('VERTICAL_NAVIGATION_FOOTER_TANDC'),
                              href: 'https://www.xing.com/terms',
                              tracking: {
                                sc_o: 'navigation_footer',
                              },
                            },
                          ],
                          [
                            'FooterNavItem',
                            {
                              key: 'privacyAtXingFooter',
                              title: t('VERTICAL_NAVIGATION_FOOTER_PRIVACY'),
                              href:
                                lang === 'de'
                                  ? 'https://privacy.xing.com/de'
                                  : 'https://privacy.xing.com/en',
                              tracking: {
                                sc_o: 'navigation_footer',
                              },
                            },
                          ],
                          [
                            'FooterNavItem',
                            {
                              key: 'privacyPolicyFooter',
                              title: t(
                                'VERTICAL_NAVIGATION_FOOTER_PRIVACY_POLICY'
                              ),
                              href:
                                lang === 'de'
                                  ? 'https://privacy.xing.com/de/datenschutzerklaerung'
                                  : 'https://privacy.xing.com/en/privacy-policy',
                              tracking: {
                                sc_o: 'navigation_footer',
                              },
                            },
                          ],
                          [
                            'FooterNavItem',
                            {
                              key: 'membershipAdministrationFooter',
                              title: t(
                                'VERTICAL_NAVIGATION_FOOTER_MEMBERSHIP_ADMINISTRATION'
                              ),
                              href: ' https://www.xing.com/settings/purchases',
                              tracking: {
                                sc_o: 'navigation_footer',
                              },
                            },
                          ],

                          [
                            'FooterNavItem',
                            {
                              key: 'membershipAdministrationFooter',
                              title: 'Tracking',
                              href: 'usercentricsConsentBanner',
                            },
                          ],
                        ],
                      },
                    },
                  ],
                  [
                    'FooterLanguage',
                    {
                      'data-qa': 'frame-footer-legal-language',
                      children: {
                        $meta: [
                          [
                            'FooterLanguageLabel',
                            { children: `${t('FOOTER_LANGUAGES')} ` },
                          ],
                          [
                            'Button',
                            {
                              children: t('ACTIVE_LANGUAGE'),
                              size: 'small',
                              variant: 'tertiary',
                              to: '/settings/account/language?sc_o=navigation_footer',
                            },
                          ],
                        ],
                      },
                    },
                  ],
                ],
              },
            },
          ],
        ],
      },
    },
  ],
];
