/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { shuffle } from './shuffle';
import { useTimedCallback } from './use-timed-callback';

const TYPE_SPEED_MS = 38;
const TYPE_PAUSE_MS = 2500;
const DELETE_SPEED_MS = 0;
const DELETE_PAUSE_MS = 500;

const PLACEHOLDER_BUCKETS = [
  // We shuffle the first bucket here, as we only need one shuffle
  shuffle([
    'JOBS_SEARCH_AI_INPUT_PLACEHOLDER_1',
    'JOBS_SEARCH_AI_INPUT_PLACEHOLDER_2',
    'JOBS_SEARCH_AI_INPUT_PLACEHOLDER_3',
    'JOBS_SEARCH_AI_INPUT_PLACEHOLDER_4',
  ]),
  [
    'JOBS_SEARCH_AI_INPUT_PLACEHOLDER_5',
    'JOBS_SEARCH_AI_INPUT_PLACEHOLDER_6',
    'JOBS_SEARCH_AI_INPUT_PLACEHOLDER_7',
    'JOBS_SEARCH_AI_INPUT_PLACEHOLDER_8',
    'JOBS_SEARCH_AI_INPUT_PLACEHOLDER_9',
    'JOBS_SEARCH_AI_INPUT_PLACEHOLDER_10',
  ],
];

type UseAnimatedPlaceholderOptions = {
  disable?: boolean;
};

export const useAnimatedPlaceholder = (
  ref: React.RefObject<HTMLTextAreaElement>,
  { disable }: UseAnimatedPlaceholderOptions = {}
) => {
  const { formatMessage } = useIntl();
  const placeholdersRef = useRef(
    PLACEHOLDER_BUCKETS.map((bucket) =>
      bucket.map((id) => formatMessage({ id }))
    )
  );
  const bucketRef = useRef(0);
  const indexRef = useRef([0, 0]);
  const charIndexRef = useRef(0);
  const { run, cancel } = useTimedCallback({ speed: TYPE_SPEED_MS });

  const getNextOptions = (delay?: number) => ({
    delay,
    cancel: Boolean(ref.current?.value),
  });

  const type = () => {
    // If there is a value, we cut the setTimeout chain
    if (ref.current?.value || disable) return;

    const bucket = placeholdersRef.current[bucketRef.current];
    const index = indexRef.current[bucketRef.current];
    const placeholder = bucket[index];
    const newPlaceholder = placeholder.slice(0, charIndexRef.current);
    ref.current?.setAttribute('placeholder', newPlaceholder);

    if (charIndexRef.current < placeholder.length) {
      ++charIndexRef.current;
      run(type);
    } else {
      charIndexRef.current = 0;
      run(remove(placeholder), getNextOptions(TYPE_PAUSE_MS));
    }
  };

  const remove = (placeholder: string) => () => {
    // If there is a value, we cut the setTimeout chain
    if (ref.current?.value) return;

    const newPlaceholder = placeholder.slice(0, -1);
    ref.current?.setAttribute('placeholder', newPlaceholder);

    if (newPlaceholder.length > 0) {
      run(remove(newPlaceholder), getNextOptions(DELETE_SPEED_MS));
    } else {
      if (bucketRef.current === 0) {
        bucketRef.current = 1;
        indexRef.current[1] = 0;
        // Second bucket is shuffled here, every time we switch to it
        placeholdersRef.current[1] = shuffle(placeholdersRef.current[1]);
      } else {
        if (indexRef.current[1] === placeholdersRef.current[1].length - 1) {
          bucketRef.current = 0;
          indexRef.current[0] =
            (indexRef.current[0] + 1) % placeholdersRef.current[0].length;
        } else {
          indexRef.current[1] =
            (indexRef.current[1] + 1) % placeholdersRef.current[1].length;
        }
      }

      run(type, getNextOptions(DELETE_PAUSE_MS));
    }
  };

  useEffect(() => {
    const shouldDisable =
      disable && (indexRef.current !== null || charIndexRef.current !== null);
    if (shouldDisable) {
      cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disable]);

  return type;
};
