import styled, { css } from 'styled-components';
import type { FlattenSimpleInterpolation } from 'styled-components';

import { Button, TextButton } from '@xing-com/button';
import {
  spaceS,
  spaceXL,
  spaceXS,
  spaceXXS,
  xdlColorBorderSoft,
} from '@xing-com/tokens';

type NoMargin = { $noMargin?: boolean };
type ActionButtonProps = { $isBottom?: boolean } & NoMargin;
type ButtonProps = { $isDelete?: boolean } & NoMargin;

const getBottomButtonCss = (
  noMargin = false
): FlattenSimpleInterpolation => css`
  justify-content: flex-start;
  border-top: 1px solid ${xdlColorBorderSoft};
  margin-top: ${noMargin ? 0 : spaceXS};
  padding-top: ${spaceS};
`;

const buttonStyles = ({
  $isDelete,
  $noMargin,
}: ButtonProps): FlattenSimpleInterpolation => css`
  ${$isDelete &&
  !$noMargin &&
  css`
    margin-left: ${spaceXL};
  `}

  & span:last-of-type {
    margin-top: ${spaceXXS};
  }
`;

export const ActionButtons = styled.div<ActionButtonProps>`
  display: flex;
  justify-content: flex-end;
  ${({ $isBottom, $noMargin }) => $isBottom && getBottomButtonCss($noMargin)}

  @media print {
    display: none;
  }
`;

export const BottomTextButton = styled(TextButton)<ButtonProps>`
  ${({ $isDelete, $noMargin }) => buttonStyles({ $isDelete, $noMargin })}
`;

export const BottomButton = styled(Button)<ButtonProps>`
  ${({ $isDelete, $noMargin }) => buttonStyles({ $isDelete, $noMargin })}
`;
