/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconVCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconVCard"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16 12h-3v1.5h3zM13 9h5v1.5h-5zM8.5 12.5a3.5 3.5 0 0 0-3.495 3.308L5 16h2a1.5 1.5 0 0 1 2.993-.145L10 16h2a3.5 3.5 0 0 0-3.5-3.5"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.263 8.762c.176-.176.408-.33.74-.404.3-.065.64-.057 1.034-.002l.117.016.106.051A1.76 1.76 0 0 1 10.25 10a1.748 1.748 0 1 1-3.5 0c0-.483.197-.922.513-1.238m1.45 1.106a.3.3 0 0 0-.036-.045c-.21-.02-.31-.009-.35 0l-.005.001A.25.25 0 0 0 8.25 10q.002.106.073.177A.249.249 0 0 0 8.75 10a.24.24 0 0 0-.037-.132"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.25 19.75V4.25h19.5v15.5zm18-14H3.75v12.5h16.5z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconVCard;
