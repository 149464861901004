import { InternalXingletLoader } from '@xing-com/crate-runtime';
import type { XingletLoaderProps } from '@xing-com/crate-xinglet';
import type { Runtime } from '@xing-com/crate-xinglet/internal';

import type { XingletErrorBoundaryProps } from './xinglet-error-boundary';
import { XingletErrorBoundary } from './xinglet-error-boundary';

type BrowserXingletLoaderProps = XingletLoaderProps &
  XingletErrorBoundaryProps & { runtime: Runtime };

export function BrowserXingletLoader({
  name,
  runtime,
  error: errorElement,
  ...otherProps
}: BrowserXingletLoaderProps): JSX.Element {
  return (
    <XingletErrorBoundary name={name} error={errorElement}>
      <InternalXingletLoader {...otherProps} name={name} runtime={runtime} />
    </XingletErrorBoundary>
  );
}
