import type { ManifestMap } from '@xing-com/crate-core-assets';
import type {
  ErrorReporter,
  ServerRequest,
  ServerResponse,
  Xinglet,
  XingletClass,
  XingletComponent,
} from '@xing-com/crate-xinglet';
import { EventEmitter, Eventual } from '@xing-com/crate-xinglet';
import type {
  BrowserRuntime,
  Runtime,
  RuntimeConfig,
  RuntimeEvents,
  ServerRuntime,
} from '@xing-com/crate-xinglet/internal';

import { createComponent } from './create-component';
import { createXinglet } from './create-xinglet';
import { extractMetadata } from './extract-metadata';

const emptyComponent: React.ComponentType<unknown> = () => null;

function withCache<T>(
  cache: Map<string, T>,
  callback: (key: string, ...rest: unknown[]) => T
): (key: string, ...rest: unknown[]) => T {
  return (key: string, ...rest) => {
    let value = cache.get(key);

    if (value === undefined) {
      value = callback(key, ...rest);
      cache.set(key, value);
    }

    return value;
  };
}

export interface CommonRuntimeOptions {
  cachedComponents?: Map<string, Eventual<XingletComponent>>;
  cachedXinglets?: Map<string, Eventual<Xinglet>>;
  config: RuntimeConfig;
  fetch?: typeof globalThis.fetch;
  languageData?: Record<string, string>;
  manifestMap: ManifestMap;
  reportError?: ErrorReporter;
  serverData: Record<string, unknown>;
}

export interface BrowserRuntimeOptions extends CommonRuntimeOptions {
  isServer: false;
  importModule<T>(name: string, entry?: string): Eventual<T>;
  importXinglet(name: string): Eventual<XingletClass>;
  registerMocks?(xingletName: string): Promise<void>;
}

export interface ServerRuntimeOptions extends CommonRuntimeOptions {
  isServer: true;
  helmetContext: Record<string, unknown>;
  importModule<T>(name: string, entry?: string): Eventual<T>;
  importXinglet(name: string): Eventual<XingletClass>;
  renderedXinglets: string[];
  request: ServerRequest;
  response: ServerResponse;
}

export type RuntimeOptions = BrowserRuntimeOptions | ServerRuntimeOptions;

export function createRuntime(options: BrowserRuntimeOptions): BrowserRuntime;
export function createRuntime(options: ServerRuntimeOptions): ServerRuntime;
export function createRuntime({
  cachedComponents = new Map(),
  cachedXinglets = new Map(),
  fetch = globalThis.fetch,
  importModule,
  importXinglet,
  languageData = {},
  manifestMap,
  reportError = console.error,
  ...options
}: RuntimeOptions): Runtime {
  const runtime: Runtime = {
    ...options,

    cachedComponents,
    cachedXinglets,
    events: new EventEmitter<RuntimeEvents>(),
    fetch,
    languageData,
    manifestMap,
    metadataMap: extractMetadata(manifestMap),
    reportError,

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    host: undefined as never,

    loadModule<T>(name: string, entry?: string) {
      return importModule<T>(name, entry);
    },
    loadXinglet: withCache(cachedXinglets, (name) => {
      return importXinglet(name).then((xingletClass) => {
        return createXinglet(xingletClass, name, runtime);
      });
    }),
    loadXingletComponent: withCache(cachedComponents, (name) => {
      return runtime.loadXinglet(name).then((xinglet) => {
        const component = xinglet.getComponent?.(runtime.host, runtime);

        return Eventual.resolve(component).then(
          (component = emptyComponent) => {
            return createComponent(name, runtime, component);
          }
        );
      });
    }),
  };

  return runtime;
}
