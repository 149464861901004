import type * as React from 'react';
import styled from 'styled-components';

import { useActiveItem, useNotifications } from '@xing-com/hub';
import { Link } from '@xing-com/link';
import type { To } from '@xing-com/link';
import { IconWithBadge } from '@xing-com/platform-layout-icon-with-badge';
import {
  spaceS,
  xdlColorHover,
  xdlColorHoverSecondary,
  scale060,
  scale080,
  spaceXXS,
  xdlColorText,
} from '@xing-com/tokens';
import { eitherOrWithCount, trackingURL } from '@xing-com/xing-tracking';

const NavItem = styled.li`
  align-items: stretch;
  display: flex;
  flex: 1 0 0%;
  justify-content: center;
`;

const Title = styled.p`
  flex: 1 1 auto;
  font-size: ${scale060};
  font-weight: normal;
  line-height: ${scale080};
  margin: ${spaceXXS} 0 0;
  text-decoration: none;
`;

const StyledLink = styled(Link)`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1 0 1px;
  flex-direction: column;
  justify-content: center;
  padding: ${spaceS} 0;
  -webkit-tap-highlight-color: transparent;

  @media (hover: hover) {
    &:hover {
      background-color: ${xdlColorHover};
    }
  }

  &:active {
    background-color: ${xdlColorHoverSecondary};
  }

  &:focus,
  &:hover {
    text-decoration: none;
  }

  color: ${xdlColorText};
`;

type Item = {
  activeIcon?: React.ElementType;
  href?: string | To;
  icon: React.ElementType;
  key: string;
  notificationKey?: string;
  title: string;
  tracking?: Record<string, string | [string, string]>;
  activeItemValues?: string[];
};

type MainListItemProps = {
  item: Item;
  onClick?: () => void;
};

export const MainListItem: React.FC<MainListItemProps> = ({
  item,
  onClick,
  ...props
}) => {
  const activeItem = useActiveItem();
  const notifications = useNotifications();

  const isActive =
    activeItem && item.activeItemValues
      ? item.activeItemValues.includes(activeItem)
      : activeItem === item.key;

  const tracking = item.tracking;
  // When active, use regular icon as fallback if no activeIcon is specified
  const IconElement = isActive ? item.activeIcon || item.icon : item.icon;
  const title = item.title;
  const notificationCount =
    notifications && notifications[item.notificationKey || ''];
  const trackingConditions = {
    sc_o_PropActionOrigin: eitherOrWithCount(notificationCount),
  };

  const completeTrackingURL =
    item.href &&
    trackingURL(item.href, 'tracking', trackingConditions)({ tracking });

  return (
    <NavItem>
      <StyledLink
        to={completeTrackingURL}
        data-qa={`frame-hnav-${item.key}`}
        data-testid={`frame-hnav-${item.key}`}
        {...props}
      >
        <IconWithBadge
          icon={IconElement}
          title={title}
          count={notificationCount}
        />
        <Title>{title}</Title>
      </StyledLink>
    </NavItem>
  );
};
