import type * as React from 'react';
import styled from 'styled-components';

import { useMetaConfig, useLoginState } from '@xing-com/hub';
import {
  mediaWideNavless,
  mediaConfined,
  mediaXWide,
} from '@xing-com/layout-tokens';
import { CenterContainer } from '@xing-com/platform-layout-view-wrapper';
import {
  scale010,
  scale090,
  scale150,
  scale200,
  spaceM,
  spaceXXL,
  xdlColorBorderSoft,
} from '@xing-com/tokens';

import { BigScreenFooter } from './big-screen-footer';
import { LegalFooterWithBranding } from './legal-footer-with-branding';
import { SmallScreenFooter } from './small-screen-footer';

export const Footer: React.FC<{
  homeLink: string;
}> = ({ homeLink }) => {
  const isLoggedIn = useLoginState() === 'LOGGED_IN';
  const { data } = useMetaConfig();

  const footerConfig = isLoggedIn
    ? data?.navigation?.loggedInFooter
    : data?.navigation.loggedOutFooter;
  const legalFooterConfig = isLoggedIn
    ? data?.navigation?.legalFooter
    : data?.navigation.loggedOutlegalFooter;

  return (
    <Box>
      <BigScreenCenterContainer>
        <BigScreenFooter config={footerConfig ?? []} />
      </BigScreenCenterContainer>
      <SmallScreenFooter config={footerConfig ?? []} />
      <CenterContainer>
        <LegalFooterWithBranding
          homeLink={homeLink}
          config={legalFooterConfig ?? []}
        />
      </CenterContainer>
    </Box>
  );
};

const Box = styled.footer`
  border-top: ${scale010} solid ${xdlColorBorderSoft};

  @media print {
    display: none;
  }
`;

const BigScreenCenterContainer = styled(CenterContainer)`
  flex-direction: column;
  padding-bottom: ${scale090};
  padding-top: ${spaceM};

  /* >= 740px */
  @media ${mediaConfined} {
    padding-bottom: ${spaceXXL};
    padding-top: ${spaceXXL};
  }

  /* >= 1310px */
  @media ${mediaXWide} {
    padding-bottom: ${scale150};
    padding-top: ${scale200};
  }

  && {
    display: none;
    @media ${mediaWideNavless} {
      display: block;
    }
  }
`;
