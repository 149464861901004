/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from 'react';

import * as Styled from './button.styles';
import type { ButtonProps } from './button.types';

export const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>((allProps, forwardedRef): JSX.Element => {
  const {
    children,
    className,
    disabled = false,
    ellipsis,
    href,
    icon: Icon,
    innerRef,
    loading,
    onClick,
    size = 'medium',
    sizeConfined,
    sizeWide,
    to,
    type,
    variant,
    ...props
  } = allProps;

  const iconSize = size === 'small' ? 18 : 24;
  const iconOnly = !children;

  const combinedRef = React.useMemo(() => {
    if (forwardedRef) {
      return typeof forwardedRef === 'function'
        ? { current: null, set: forwardedRef }
        : forwardedRef;
    }
    return innerRef;
  }, [forwardedRef, innerRef]);

  const commonProps = {
    $ellipsis: ellipsis,
    $iconOnly: iconOnly,
    $size: size,
    $sizeConfined: sizeConfined,
    $sizeWide: sizeWide,
    $variant: variant,
    'aria-label': loading ? 'loading' : undefined,
    'data-xds': 'Button',
    className,
    disabled: disabled || loading,
    onClick,
    ref: combinedRef,
  };

  const buttonContent = (
    <Styled.InnerContent $ellipsis={ellipsis}>
      {!loading && Icon && (
        <Styled.IconContainer $iconSize={iconSize}>
          <Icon width={iconSize} height={iconSize} aria-hidden="true" />
        </Styled.IconContainer>
      )}
      {loading && (
        <Styled.Loading
          $size={size}
          $sizeConfined={sizeConfined}
          $sizeWide={sizeWide}
          aria-hidden="true"
        />
      )}
      <Styled.Text $ellipsis={ellipsis} $loading={loading}>
        {children}
      </Styled.Text>
    </Styled.InnerContent>
  );

  if (to || href) {
    return (
      <Styled.RouterButton
        {...commonProps}
        {...(to && { to })}
        {...(href && { href })}
        {...props}
      >
        {buttonContent}
      </Styled.RouterButton>
    );
  }

  return (
    <Styled.Button {...commonProps} type={type} {...props}>
      {buttonContent}
    </Styled.Button>
  );
});

Button.displayName = 'Button';
