import { redirectTo, useMatch } from '@reach/router';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { useExperiment, useExperiments } from '@xing-com/hub';

export const useIsAiSearch = (): boolean => {
  const { loading: isLoadingExperiments } = useExperiments();
  const { isLoggedOut } = useLoginState();
  const isExperimentActiveLO = useExperiment('ABACUS-443') === 'B';
  const isAiSearchActive = !isLoggedOut || isExperimentActiveLO;
  const isAiSearchUrl = !!useMatch(ROUTES.searchAi);
  const isAiSearchLandingUrl = !!useMatch(ROUTES.searchAiLanding);

  // Temporary redirect while we run the experiment
  if (
    (isAiSearchLandingUrl || isAiSearchUrl) &&
    !isLoadingExperiments &&
    !isAiSearchActive
  ) {
    redirectTo(ROUTES.search);
    return false;
  }

  return isAiSearchUrl;
};
