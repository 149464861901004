import type * as React from 'react';

import type { FallbackImageType } from '../util';
import * as Styled from './super-ellipse.styles';
const { sizes } = Styled;

export type SuperEllipseSize = keyof typeof sizes;

export type SuperEllipseProps = {
  /** The alt attribute for the img element */
  alt?: string;
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Blurs the image */
  blur?: boolean;
  /** Add custom CSS */
  className?: string;
  /** Add the profile name */
  profileName?: string;
  /** The size on mobile screens or larger */
  size?: SuperEllipseSize;
  /** The size on tablet screens or larger */
  sizeConfined?: SuperEllipseSize;
  /** The size on desktop screens or larger */
  sizeWide?: SuperEllipseSize;
  /** The size on larger desktop screens or larger */
  sizeXWide?: SuperEllipseSize;
  /** The src attribute for the img element */
  src?: string;
  /** Used to render icon inside the Avatar. If there is no src prop it will not be used and no img will be rendered by default */
  type?: FallbackImageType;
} & React.HTMLAttributes<HTMLDivElement>;
