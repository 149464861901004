import * as React from 'react';

import { IconCross, IconTick, IconPlus } from '@xing-com/icons';

import { TagContent } from './tag-content';
import * as Styled from './tag.styles';
import type { AddTagProps } from './tag.types';

export const AddTag = React.forwardRef<HTMLDivElement, AddTagProps>(
  (
    {
      children,
      className,
      disabled,
      fontWeight,
      maxWidth = '330px',
      onClick,
      profile,
      selected,
      value,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const hasChildren = Boolean(children);
    const hasLeadingContent = true;
    const hasTrailingContent = !hasChildren;

    const handleOnClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void => {
      if (!disabled && onClick) {
        event.currentTarget.dataset.value = value;
        onClick(event);
      }
    };

    const [hovered, setHovered] = React.useState(false);
    const [touched, setTouched] = React.useState(false);

    return (
      <Styled.InteractiveTag
        $disabled={disabled}
        $fontWeight={fontWeight}
        $hasLeadingContent={hasLeadingContent}
        $hasTrailingContent={hasTrailingContent}
        $profile={Boolean(profile)}
        $selected={selected}
        aria-disabled={disabled}
        as="button"
        className={className}
        data-xds="AddTag"
        disabled={disabled}
        onClick={handleOnClick}
        ref={forwardedRef}
        role="button"
        type="button"
        value={value}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
        onTouchStart={() => setTouched(true)}
        onTouchEnd={() => setTouched(false)}
        {...props}
      >
        <TagContent
          badgeProps={undefined}
          disabled={disabled}
          leadingIcon={
            selected ? (
              (hovered || touched) && onClick && !disabled ? (
                <IconCross
                  aria-hidden="true"
                  data-qa="icon-shape-cross"
                  data-testid="icon-shape-cross"
                />
              ) : (
                <IconTick
                  aria-hidden="true"
                  data-qa="icon-shape-tick"
                  data-testid="icon-shape-tick"
                />
              )
            ) : (
              <IconPlus
                aria-hidden="true"
                data-qa="icon-shape-plus"
                data-testid="icon-shape-plus"
              />
            )
          }
          maxWidth={maxWidth}
          profile={profile}
          selected={selected}
          trailingIcon={null}
        >
          {children}
        </TagContent>
      </Styled.InteractiveTag>
    );
  }
);

AddTag.displayName = 'AddTag';
