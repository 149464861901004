/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useId } from '@react-aria/utils';
import * as React from 'react';

import { CharacterCounter } from '../character-counter';
import * as Styled from './form-field.styles';
import type { FormFieldProps } from './form-field.types';

export const FormField = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  FormFieldProps
>(
  (
    {
      action,
      characterCounter,
      className,
      disabled,
      error,
      helperText,
      icon: Icon,
      id,
      innerRef,
      label = '',
      multiline,
      maxCharacterCount,
      onBlur,
      onChange,
      onFocus,
      showCharacterCounter,
      size = 'medium',
      sizeConfined,
      sizeWide,
      success,
      value = '',
      variant,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const localRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null);
    const ref = innerRef || forwardedRef || localRef;
    const idRef = useId(id);
    const helperTextId = useId();
    const [hasFocus, setHasFocus] = React.useState(false);
    const [currentCount, setCount] = React.useState(0);

    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
      onChange?.(e as any);
      if (showCharacterCounter && e.target.value.length) {
        setCount(e.target.value.length);
      }
    };

    const handleFocus = (
      e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
      setHasFocus(true);
      onFocus?.(e as any);
    };

    const handleBlur = (
      e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
      setHasFocus(false);
      onBlur?.(e as any);
    };

    React.useEffect(() => {
      if (showCharacterCounter) {
        const getRefValue = ():
          | HTMLInputElement
          | HTMLTextAreaElement
          | null => {
          if (typeof ref === 'function') {
            return null;
          }

          if (ref && 'current' in ref) {
            return ref.current;
          }

          return null;
        };

        const element = getRefValue();
        if (element && 'value' in element) {
          setCount(element.value.length);
        }
      }
    }, [ref, showCharacterCounter, value]);

    const isFilled = typeof value === 'number' ? value !== 0 : value.length > 0;
    const iconSize = size === 'small' ? 18 : 24;

    const commonProps = {
      $error: error,
      $isDisabled: disabled,
      $isFilled: isFilled,
      $size: size,
      $sizeConfined: sizeConfined,
      $sizeWide: sizeWide,
      $success: success,
      $variant: variant,
      'aria-describedby': helperText ? helperTextId : undefined,
      'data-xds': 'FormField',
      disabled: disabled,
      id: idRef,
      onBlur: handleBlur,
      onChange: handleChange,
      onFocus: handleFocus,
      ref,
      value: value,
    };

    const renderInput = (): JSX.Element => {
      if (multiline) {
        return <Styled.Textarea {...commonProps} {...props} />;
      }

      return (
        <Styled.Input
          $hasAction={Boolean(action)}
          $isIconSet={Boolean(Icon)}
          {...commonProps}
          {...props}
        />
      );
    };

    const renderIcon = (): JSX.Element | null => {
      if (!Icon) return null;

      return (
        <Styled.IconContainer $iconSize={iconSize} $isDisabled={disabled}>
          <Icon width={iconSize} height={iconSize} aria-hidden="true" />
        </Styled.IconContainer>
      );
    };

    const renderAction = (): JSX.Element | null => {
      if (!action) return null;

      return (
        <Styled.ActionWrapper $isDisabled={disabled}>
          {action}
        </Styled.ActionWrapper>
      );
    };

    const renderLabel = (): JSX.Element | null => {
      if (!label) return null;

      return (
        <Styled.Label
          $hasFocus={hasFocus}
          $isDisabled={disabled}
          $isFilled={isFilled}
          $isIconSet={Boolean(Icon)}
          $size={size}
          $sizeConfined={sizeConfined}
          $sizeWide={sizeWide}
          htmlFor={idRef}
        >
          {label}
        </Styled.Label>
      );
    };

    const renderHelperText = (): JSX.Element | null => {
      if (!helperText) return null;
      return (
        <Styled.HelperText
          $error={error}
          $isDisabled={disabled}
          id={helperTextId}
          size="xsmall"
        >
          {helperText}
        </Styled.HelperText>
      );
    };

    const renderCharacterCounter = (): JSX.Element | null => {
      if (characterCounter || (characterCounter && showCharacterCounter)) {
        return (
          <Styled.CharacterCounterArea size="xsmall">
            {characterCounter}
          </Styled.CharacterCounterArea>
        );
      }

      if (showCharacterCounter && !characterCounter) {
        return (
          <Styled.CharacterCounterArea size="xsmall">
            <CharacterCounter
              currentCount={currentCount}
              maxCount={maxCharacterCount || 0}
            />
          </Styled.CharacterCounterArea>
        );
      }

      return null;
    };

    const renderHelperContent = (): JSX.Element | null => {
      if (!helperText && !characterCounter && !showCharacterCounter)
        return null;

      return (
        <Styled.HelperContainer>
          {renderHelperText()}
          {renderCharacterCounter()}
        </Styled.HelperContainer>
      );
    };

    return (
      <Styled.Wrapper className={className}>
        <Styled.InputWrapper>
          {renderInput()}
          {renderIcon()}
          {renderAction()}
          {renderLabel()}
        </Styled.InputWrapper>
        {renderHelperContent()}
      </Styled.Wrapper>
    );
  }
);

FormField.displayName = 'FormField';
