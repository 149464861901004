import { useLocation } from '@reach/router';

import { CarouselSection } from '@xing-com/crate-x4e-carousel-section';
import { useServerData } from '@xing-com/crate-xinglet';
import { Container } from '@xing-com/layout-primitives';
import { xdlColorBackgroundSecondary, scale430 } from '@xing-com/tokens';

import { Card } from '../card';
import { shuffle } from '../shuffle';
import { StyledCarouselSection } from '../startpage-logged-out.styles';
import { RecruitersData } from './data/recruiters-data';

export const RecruitersSection: React.FC = () => {
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);

  const seed = useServerData('recruitersStartPageSeed', Math.random());
  const recruiters =
    queryParams.get('allRecruiters') === 'true'
      ? RecruitersData.sort((a, b) => a.id - b.id)
      : shuffle(RecruitersData, seed).slice(0, 20);

  return (
    <StyledCarouselSection $bgColor={xdlColorBackgroundSecondary}>
      <Container variant="expanded">
        <CarouselSection
          titleKey={'RECRUITERS_SECTION_SECOND_TITLE'}
          subtitleKey={'RECRUITERS_SECTION_SECOND_SUBTITLE'}
          itemWidth="346px"
          itemMinWidth="346px"
          itemMaxWidth="346px"
          // @ts-expect-error FIXME: SC6
          itemHeight={scale430}
          itemMinHeight={scale430}
          itemMaxHeight={scale430}
          itemSnapPosition="center"
          useFullWidth={true}
        >
          {recruiters.map((cardData, index) => (
            <Card {...cardData} cardPosition={index + 1} key={cardData.id} />
          ))}
        </CarouselSection>
      </Container>
    </StyledCarouselSection>
  );
};
