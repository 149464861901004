import type React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type RecruiterCard = {
  cardPosition: number;
  photoUrl: string;
  flagText: string;
  rating?: number;
  name: string;
  companyLogo?: string;
  jobTitle: string;
  insight: string;
  company?: string;
  profileUrl: string;
};

import { Button } from '@xing-com/button';
import { IconStarFilled } from '@xing-com/icons';
import { BodyCopy, Headline } from '@xing-com/typography';

import {
  StyledAnchor,
  StyledCard,
  StyledAnchorProfileImage,
  ProfileImage,
  LowerSectionContainer,
  FlagRatingContainer,
  Flag,
  CompanyRatingWrapper,
  RecruiterInfoWrapper,
  StyledIcon,
  Insight,
  InsightsWrapper,
  RecruiterInfoIcon,
  CardCtaContainer,
} from './card.styles';

const tracking = {
  recruiter: {
    card: 'losp_recruiter_profile_click',
    button: 'losp_recruiter_profile_click',
  },
};

export const Card: React.FC<RecruiterCard> = ({
  cardPosition,
  photoUrl,
  flagText,
  rating,
  companyLogo,
  name,
  jobTitle,
  insight,
  company,
  profileUrl,
}) => {
  const { formatMessage } = useIntl();

  const cardSc_o = tracking.recruiter.card;
  const buttonSc_o = tracking.recruiter.button;
  const cardUrlWithTracking = `${profileUrl}?sc_o=${cardSc_o}&sc_o_PropActionOrigin=losp_recruiter_and_employer_card_position_${cardPosition}`;
  const buttonUrlWithTracking = `${profileUrl}?sc_o=${buttonSc_o}&sc_o_PropActionOrigin=losp_recruiter_and_employer_card_position_${cardPosition}`;

  const handleCardClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    window.location.assign(cardUrlWithTracking);
  };

  const handleButtonClick = (e: MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    window.location.assign(buttonUrlWithTracking);
  };

  return (
    <StyledAnchor onClick={handleCardClick} href={profileUrl}>
      <StyledCard>
        <StyledAnchorProfileImage>
          <ProfileImage
            size="xlarge"
            src={photoUrl}
            alt="profile-image"
            // @ts-expect-error FIXME: SC6
            loading="lazy"
          />
        </StyledAnchorProfileImage>
        <LowerSectionContainer>
          {rating ? (
            <FlagRatingContainer>
              <Flag>
                {/* @ts-expect-error FIXME: SC6 */}
                <BodyCopy size="small" noMargin noPadding>
                  {formatMessage({ id: flagText })}
                </BodyCopy>
              </Flag>
              <CompanyRatingWrapper>
                <IconStarFilled height={12} width={12} />
                {/* @ts-expect-error FIXME: SC6 */}
                <BodyCopy size="small" noMargin noPadding>
                  {rating}
                </BodyCopy>
              </CompanyRatingWrapper>
            </FlagRatingContainer>
          ) : (
            <Flag>
              {/* @ts-expect-error FIXME: SC6 */}
              <BodyCopy size="small" noMargin noPadding>
                {formatMessage({ id: flagText })}
              </BodyCopy>
            </Flag>
          )}

          <Headline size="large" as="h3">
            {name}
          </Headline>
          <RecruiterInfoWrapper>
            {companyLogo ? (
              <>
                <RecruiterInfoIcon
                  size="small"
                  src={companyLogo}
                  alt={company}
                />
                <BodyCopy noMargin>
                  {jobTitle}, {company}
                </BodyCopy>
              </>
            ) : (
              <BodyCopy>{jobTitle}</BodyCopy>
            )}
          </RecruiterInfoWrapper>
          <InsightsWrapper>
            <StyledIcon height={25} width={20} />
            <Insight size="small" noMargin>
              {formatMessage({ id: insight })}
            </Insight>
          </InsightsWrapper>
        </LowerSectionContainer>

        <CardCtaContainer>
          <Button
            variant="tertiary"
            href={profileUrl}
            // @ts-expect-error FIXME: SC6
            onClick={handleButtonClick}
          >
            <FormattedMessage id="LOSP_RECRUITERS_SECTION_RECRUITER_CTA" />
          </Button>
        </CardCtaContainer>
      </StyledCard>
    </StyledAnchor>
  );
};
