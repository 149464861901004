import times from 'lodash/times';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { scale130, scale150 } from '@xing-com/tokens';

import { SkeletonPillShape } from '..';
import type { SkeletonContentSwitcherProps } from '..';
import * as Styled from './skeleton-content-switcher.styles';

export const SkeletonContentSwitcher = React.forwardRef<
  HTMLDivElement,
  SkeletonContentSwitcherProps
>(
  (
    { className, amount = 3, size = 'medium', ...props },
    forwardedRef
  ): JSX.Element => {
    const widthMultiplicator = 3;
    const sizes = {
      small: scale130,
      medium: scale150,
    };
    const intl = useIntl();

    return (
      <Styled.Container
        aria-busy="true"
        aria-label={intl.formatMessage({
          id: 'XDS_SKELETON_GENERIC_A11Y',
          defaultMessage: 'Loading',
        })}
        className={className}
        data-xds="SkeletonContentSwitcher"
        ref={forwardedRef}
        role="status"
        {...props}
      >
        {times(amount, (index) => (
          <SkeletonPillShape
            height={sizes[size]}
            key={index}
            width={`calc(${sizes[size]} * ${widthMultiplicator})`}
          />
        ))}
      </Styled.Container>
    );
  }
);

SkeletonContentSwitcher.displayName = 'SkeletonContentSwitcher';
