import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';

import type {
  Host,
  RuntimeHost,
  Xinglet,
  XingletFunction,
} from '@xing-com/crate-xinglet';
import type { Runtime } from '@xing-com/crate-xinglet/internal';

// NOTE: importing the fragment type here violates some settings, but we want to
// have them in the repo root
/* eslint-disable  */
// @ts-ignore
import fragmentTypes from '../../../../fragmentTypes.json';
/* eslint-enable */

import { BrowserRoot } from './browser/root';
import type { RootComponentType } from './root';
import type { ServerRoot } from './server/root';
import type { HopsUploadRequestMutationVariables } from './upload-request-mutation.gql-types';

function importRoot(runtime: Runtime): RootComponentType {
  if (runtime.isServer) {
    const { value: module } = runtime.loadModule<{
      ServerRoot: typeof ServerRoot;
    }>('@xing-com/crate-core-hops-environment', 'server-root');

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return module!.ServerRoot;
  } else {
    return BrowserRoot;
  }
}

export interface HopsEnvironmentExtension {
  executeCommand:
    | XingletFunction<
        HopsEnvironment,
        '@xing-com/crate-core-hops-environment.apolloClient'
      >
    | XingletFunction<
        HopsEnvironment,
        '@xing-com/crate-core-hops-environment.uploadFile'
      >;
}

export type HopsEnvironmentProps = React.PropsWithChildren<{
  basePath?: string;
}>;

export default class HopsEnvironment implements Xinglet {
  private client?: ApolloClient<unknown>;

  public constructor(private host: RuntimeHost) {}

  public '@xing-com/crate-core-hops-environment.apolloClient'():
    | ApolloClient<unknown>
    | undefined {
    return this.client;
  }

  public async '@xing-com/crate-core-hops-environment.uploadFile'(
    application: HopsUploadRequestMutationVariables['application'],
    file: File,
    errorHandler?: (id: string) => void
  ): Promise<string | null> {
    if (!this.client) {
      throw new Error('No upload outside of hops environment');
    }
    return await (
      await import('./upload')
    ).uploadFile(this.client, application, file, errorHandler);
  }

  public getComponent(
    host: Host,
    runtime: Runtime
  ): React.ComponentType<HopsEnvironmentProps> {
    const Root = importRoot(runtime);

    const cache = new InMemoryCache({
      possibleTypes: fragmentTypes,
    });

    const { APOLLO_STATE: state } = runtime.serverData;
    if (state) cache.restore({ ...state });

    const client = (this.client = new ApolloClient({
      cache,
      connectToDevTools: host.isPreview,
      link: new HttpLink({
        fetch: runtime.fetch,
        uri: runtime.config.xingOneEndpoint,
      }),
      ssrMode: runtime.isServer,
    }));

    if (runtime.isServer) {
      runtime.events.once('server-rendered', () => {
        try {
          runtime.serverData['APOLLO_STATE'] = cache.extract();
        } finally {
          cache.gc({
            resetResultCache: true,
            resetResultIdentities: true,
          });
          cache.reset();
        }
      });
    }

    const { language } = runtime.config;
    const { languageData: messages = {} } = runtime;
    const helmetContext = runtime.isServer ? runtime.helmetContext : {};

    return ({ basePath = '/', children }) => {
      return (
        <ApolloProvider client={client}>
          <HelmetProvider context={helmetContext}>
            <IntlProvider
              locale={language}
              messages={messages}
              textComponent={React.Fragment}
            >
              <Root basePath={basePath}>{children}</Root>
            </IntlProvider>
          </HelmetProvider>
        </ApolloProvider>
      );
    };
  }
}
