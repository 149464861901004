import { useEffect, type FC, type ReactElement } from 'react';
import { StyleSheetManager, type ServerStyleSheet } from 'styled-components';

import type { Runtime } from '@xing-com/crate-xinglet/internal';
import { RuntimeProvider } from '@xing-com/crate-xinglet/internal';

const LoadingStateHandler: FC<{ runtime: Runtime }> = ({ runtime }) => {
  useEffect(() => {
    (function waitForXinglets() {
      const loadingXinglets = [...runtime.cachedXinglets.values()].filter(
        (eventual) => !eventual.settled
      );

      if (loadingXinglets.length) {
        Promise.all(loadingXinglets).then(waitForXinglets, waitForXinglets);
      } else {
        document
          .querySelector('#app[data-mountpoint]')
          ?.setAttribute('data-xinglets-settled', '');
      }
    })();
  });

  return <></>;
};

export function createEntryPoint(
  runtime: Runtime,
  name: string,
  { sheet, ...props }: { sheet?: ServerStyleSheet; [key: string]: unknown } = {}
): ReactElement {
  return (
    <StyleSheetManager sheet={sheet?.instance}>
      <RuntimeProvider runtime={runtime}>
        <LoadingStateHandler runtime={runtime} />
        <runtime.host.XingletLoader {...props} runtime={runtime} name={name} />
      </RuntimeProvider>
    </StyleSheetManager>
  );
}
