import * as React from 'react';
import { useIntl } from 'react-intl';

import { scale130, scale190 } from '@xing-com/tokens';

import { SkeletonRectangle } from '../';
import type { SkeletonTagProps } from './skeleton-tag.types';

export const SkeletonTag = React.forwardRef<HTMLDivElement, SkeletonTagProps>(
  ({ className, ...props }, forwardedRef): JSX.Element => {
    const intl = useIntl();

    return (
      <SkeletonRectangle
        aria-busy="true"
        aria-label={intl.formatMessage({
          id: 'XDS_SKELETON_GENERIC_A11Y',
          defaultMessage: 'Loading',
        })}
        className={className}
        cornerRadius={scale130}
        data-xds="SkeletonTag"
        height={scale130}
        ref={forwardedRef}
        role="status"
        width={scale190}
        {...props}
      />
    );
  }
);

SkeletonTag.displayName = 'SkeletonTag';
