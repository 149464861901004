import * as React from 'react';

import * as Styled from './icon-button.styles';
import type { IconButtonProps } from './icon-button.types';

export const IconButton = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  IconButtonProps
>(
  (
    {
      className,
      disabled,
      href,
      icon: Icon,
      innerRef,
      loading,
      onClick,
      size = 'medium',
      sizeConfined,
      sizeWide,
      to,
      type,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const iconSize = size === 'small' ? 18 : 24;

    const combinedRef = React.useMemo(() => {
      if (forwardedRef) {
        return typeof forwardedRef === 'function'
          ? { current: null, set: forwardedRef }
          : forwardedRef;
      }
      return innerRef;
    }, [forwardedRef, innerRef]);

    const commonProps = {
      $size: size,
      $sizeConfined: sizeConfined,
      $sizeWide: sizeWide,
      'aria-label': loading ? 'loading' : undefined,
      'data-xds': 'IconButton',
      className,
      disabled: disabled || loading,
      onClick,
      ref: combinedRef,
    };

    const buttonContent = (
      <React.Fragment>
        {!loading && Icon && (
          <Styled.IconContainer $iconSize={iconSize}>
            <Icon width={iconSize} height={iconSize} aria-hidden="true" />
          </Styled.IconContainer>
        )}
        {loading && (
          <Styled.Loading
            $size={size}
            $sizeConfined={sizeConfined}
            $sizeWide={sizeWide}
            aria-hidden="true"
          />
        )}
      </React.Fragment>
    );

    if (to || href) {
      return (
        <Styled.RouterIconButton
          {...(to && { to })}
          {...(href && { href })}
          {...commonProps}
          {...props}
        >
          {buttonContent}
        </Styled.RouterIconButton>
      );
    }

    return (
      <Styled.IconButton type={type} {...commonProps} {...props}>
        {buttonContent}
      </Styled.IconButton>
    );
  }
);

IconButton.displayName = 'IconButton';
